.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.left-small-text {
  text-align: left;
  font-size: 0.8em;
}

.nav-link.active {
  background-color: #cccccd;
  border-radius: 6px;
}

.date-hint-animation {
  transform: rotate(90deg);
  margin-bottom: 5px;
  animation-name: date-hint;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes date-hint {
  from {
    opacity: 30%;
    margin-right: 10px;
  }
  50% {
    opacity: 100%;
    margin-right: 5px;
  }
  to {
    opacity: 30%;
    margin-right: 10px;
  }
}

.card-honors {
  position: absolute;
  right: 2px;
  top: 7px;
}

.notch-container {
  position: absolute;
  top: 2.55em;
  left: 0.1em;
  line-height: 0;
}

.notch-container img {
  /*transform: translateY(-50%);*/
}

.loss_progress_bar {
  position: absolute;
  top: 3.1em;
  left: 0em;
  width: 100%;
  height: 2px;
}

.next-percent-span {
  color: #dc3545;
}

.loss_est_progress_bar {
  height: 10px;
}

.loss_est_container {
  display: flex;
  width: 100%;
  margin: 0 auto;
  font-size: 0.8em;
}

.loss_est_column {
  padding: 0px;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
  width: 100%;
}

.right {
  text-align: right;
}


